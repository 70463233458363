import { Injectable } from '@angular/core';
import { XclSignatureContext } from '@mdib-xcl/core';
import { FunctionalFeedbacksFromXclOrderExtractor, MonogoalOrderXCLModel, XclHttpService } from '@mdib-xcl/http';
import { InstantPaymentXcl } from '../../model/instant-payment-xcl';
import { InstantPaymentsMapperXclService } from './instant-payments-mapper-xcl.service';
import { XclAPI } from '@mdib-xcl/utils';
import { ParameterModel } from '@mdib/http';
import { InstantPaymentsService, PaymentOperation } from '@mdib/payments';
import { Feedback, FeedbackTypes, ServiceResponse, Status } from '@mdib/utils';
import { throwError, Observable } from 'rxjs/index';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PersonsService } from '@mdib/customers';
import { SignableOperation } from '@mdib/signature';

@Injectable({
	providedIn: 'root',
})
export class InstantPaymentsXclService extends InstantPaymentsService {

	private readonly baseUri = 'instant-payments';
	private currentOrderReference: string;

	constructor(private xclHttpService: XclHttpService,
				private feedbackExtractor: FunctionalFeedbacksFromXclOrderExtractor,
				private mapper: InstantPaymentsMapperXclService,
				private personsService: PersonsService) {
		super();
	}

	confirm(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null> | null> {

alert("Confirm|"+payment.type+"|"+payment.reference);
		const params: ParameterModel[] = this.parameters(this.currentOrderReference, XclAPI.confirm);

		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;
				})
			);

		return this.getFunctionalObservable(xclObservable, payment);
	}

	sign(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {

alert("Sign|"+payment.type+"|"+payment.reference);
		const params: ParameterModel[] = this.parameters(this.currentOrderReference, XclAPI.sign);

		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment).pipe(
			tap(() => {
				if (payment.status === Status.Error) {
					throw ServiceResponse.emptyWithOnefeedback(new Feedback('errorWhenSigningPayment', FeedbackTypes.FRONTEND_ERROR, 'Something went wrong when signing the instant payment operation'));
				}
			}));
	}

	validate(payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null> | null>> {
alert("Validate|"+payment.type+"|"+payment.reference);
		return this.init().pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.validateInstantPayment(order.reference, payment)));
	}

	/**
	 * Init an instant payment
	 * @return {Observable<MonogoalOrderXCLModel<any>>}
	 */
	public init(): Observable<MonogoalOrderXCLModel<any>> {
		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.POST) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	public getDraft(reference: string): Observable<ServiceResponse<PaymentOperation<null> | null>> {

		let draftPaymentStream: Observable<ServiceResponse<PaymentOperation<null> | null>>;

		const params: ParameterModel[] = [
			new ParameterModel('orderReference', reference),
		];

		draftPaymentStream = this.xclHttpService.execute('order', XclHttpService.GET, params, null).pipe(
			map((xclOrder: MonogoalOrderXCLModel<any>) => {
				return new ServiceResponse<PaymentOperation<null>>(
					this.mapper.fromXcl(new PaymentOperation<null>(), xclOrder, XclAPI.retrieve),
					this.feedbackExtractor.extract(xclOrder));
			}));

		return draftPaymentStream;
	}

	/**
	 * Retrieve an instant payment
	 * @param {MonogoalOrderXCLModel<any>} order
	 * @returns {Observable<MonogoalOrderXCLModel<any>>}
	 */
	public retrieve(reference: string): Observable<MonogoalOrderXCLModel<any>> {

		this.currentOrderReference = reference;

		const params: ParameterModel[] = this.parameters(reference, XclAPI.retrieve);
		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, params) as Observable<MonogoalOrderXCLModel<any>>;
		return xclObservable;
	}

	public get(reference: string): Observable<ServiceResponse<PaymentOperation<null>>> {
		const paymentOperation = new PaymentOperation<null>();

		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<any>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.GET, parameters, paymentOperation,
		) as Observable<MonogoalOrderXCLModel<any>>;

		return this.getFunctionalObservable(xclObservable, paymentOperation);
	}

	/**
	 * Validate an instant payment
	 * @param {string} reference
	 * @param {PaymentOperation<null>} payment
	 * @returns {Observable<ServiceResponse<PaymentOperation<null>>>}
	 */
	public validateInstantPayment(reference: string, payment: PaymentOperation<null>): Observable<ServiceResponse<PaymentOperation<null>>> {

		const params: ParameterModel[] = this.parameters(reference, XclAPI.validate);

		// For already initialized SEPA payment
		payment.reference = null;

		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> =
			this.personsService.getRepresentedClientNumber().pipe(
				mergeMap(representedClient => {
					const paymentXcl = this.mapper.toXcl(payment);
					paymentXcl.representedClientNumber = representedClient;
					return this.xclHttpService.execute(this.baseUri, XclHttpService.PUT, params, paymentXcl) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;
				}),
			);

		return this.getFunctionalObservable(xclObservable, payment, XclAPI.validate).pipe(
			tap(() => {
				payment.signatureContext = new XclSignatureContext({signableReference: this.currentOrderReference});
				if (payment.status === Status.NotAuthorizedToSign) {
					throwError('Payment is not authorized to sign !');
				}
			}));
	}

	public delete(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {
		return this.initDeleteInstantPayment(reference).pipe(
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.retrieve(order.reference)),
			mergeMap((order: MonogoalOrderXCLModel<any>) => this.validateDeleteInstantPayment(order.reference)));
	}

	public confirmDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>> | null>> {

		let confirmPaymentStream: Observable<ServiceResponse<SignableOperation<PaymentOperation<null> | null> | null>>;

		confirmPaymentStream = this.confirmDeletePaymentOperation(signablePaymentOperation.reference);

		return confirmPaymentStream;
	}

	public signDelete(signablePaymentOperation: SignableOperation<PaymentOperation<null>>): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', 'sign'),
			new ParameterModel('reference', signablePaymentOperation.reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters, null,
		) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;

		return this.getFunctionalSignableObservable(xclObservable, signablePaymentOperation.model);

	}

	private initDeleteInstantPayment(reference: string): Observable<MonogoalOrderXCLModel<PaymentOperation<null>>> {
		const parameters: ParameterModel[] = [
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<PaymentOperation<null>>> = this.xclHttpService.execute(
			this.baseUri + '-get', XclHttpService.DELETE, parameters,
		) as Observable<MonogoalOrderXCLModel<PaymentOperation<null>>>;

		return xclObservable;
	}

	private validateDeleteInstantPayment(reference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		const paymentOperation = new PaymentOperation<null>();

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', XclAPI.validate),
			new ParameterModel('reference', reference),
		];

		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;

		return this.getFunctionalSignableObservable(xclObservable, paymentOperation);
	}

	private confirmDeletePaymentOperation(operationReference: string): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {

		const signablePaymentOperation = new PaymentOperation<null>();

		const parameters: ParameterModel[] = [
			new ParameterModel('restmethod', XclAPI.confirm),
			new ParameterModel('reference', operationReference),
		];
		const xclObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>> = this.xclHttpService.execute(
			this.baseUri, XclHttpService.PUT, parameters,
		) as Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>;

		return this.getFunctionalSignableObservable(xclObservable, signablePaymentOperation);
	}



	private parameters(reference: string, phase: string): ParameterModel[] {
		return <ParameterModel[]>[
			new ParameterModel(XclAPI.rest, phase),
			new ParameterModel(XclAPI.reference, reference),
		];
	}

	private getFunctionalObservable(technicalObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>, paymentOperation: PaymentOperation<null>, phase = XclAPI.retrieve): Observable<ServiceResponse<PaymentOperation<null>>> {
		return technicalObservable.pipe(map((instantPayment: MonogoalOrderXCLModel<InstantPaymentXcl>) => {
			return new ServiceResponse(
				this.mapper.fromXcl(paymentOperation, instantPayment, phase),
				this.feedbackExtractor.extract(instantPayment),
			);
		}));
	}

	private getFunctionalSignableObservable(technicalObservable: Observable<MonogoalOrderXCLModel<InstantPaymentXcl>>, paymentOperation: PaymentOperation<null>, phase = XclAPI.retrieve): Observable<ServiceResponse<SignableOperation<PaymentOperation<null>>>> {
		return technicalObservable.pipe(map((instantPayment: MonogoalOrderXCLModel<InstantPaymentXcl>) => {
			const signableOperation = new SignableOperation<PaymentOperation<null>>(this.mapper.fromXcl(paymentOperation, instantPayment, phase));
			signableOperation.signatureContext = signableOperation.model.signatureContext;
			signableOperation.signature = signableOperation.model.signature;
			signableOperation.signatureTypesAllowed = signableOperation.model.signatureTypesAllowed;
			signableOperation.reference = instantPayment.reference;
			signableOperation.status = instantPayment.orderNature.isSignatureRequired ? Status.ToSign : null;
			return new ServiceResponse(
				signableOperation,
				this.feedbackExtractor.extract(instantPayment),
			);
		}));
	}

}
